import Loader from "../../Component/UI/Loader";
import { useStaticPageData } from "../../Middleware/useStaticPageData";
import useFetchMetaTags from "../../Middleware/useFetchMetaTags";
// import { Helmet } from "react-helmet-async";

const MagazineArchive = () => {
  const {
    data: magazine,
    isLoading,
    error,
  } = useStaticPageData("digital-edition");
  // const { data: tags } = useFetchMetaTags("digital-edition");

  const openMagazineInNewWindow = (url) => {
    window.open(url, "popup", "toolbar=no,menubar=no,width=1200,height=800");
  };

  return (
    <>
      {/* <Helmet>
        <title>{`${tags?.data?.title}`}</title>
        <meta name="description" content={`${tags?.data?.description}`} />
        <link rel="canonical" href={`${tags?.data?.canonical}`} />
        <meta property="og:locale" content={`${tags?.data?.og_locale}`} />
        <meta property="og:type" content={`${tags?.data?.og_type}`} />
        <meta property="og:title" content={`${tags?.data?.og_title}`} />
        <meta
          property="og:description"
          content={`${tags?.data?.og_description}`}
        />
        <meta property="og:url" content={`${tags?.data?.og_url}`} />
        <meta property="og:site_name" content={`${tags?.data?.og_site_name}`} />
        <meta
          property="og:image"
          content={`https://admin.asiantrader.biz/wp-content/uploads/sites/1/2024/09/AT-logo-White-3-1.png`}
        />
        <meta
          property="og:updated_time"
          content={`${tags?.data?.og_updated_time}`}
        />
        <meta
          property="article:published_time"
          content={`${tags?.data?.article_published_time}`}
        />
        <meta
          property="article:modified_time"
          content={`${tags?.data?.article_modified_time}`}
        />
        <meta name="twitter:card" content={`${tags?.data?.twitter_card}`} />
        <meta name="twitter:title" content={`${tags?.data?.twitter_title}`} />
        <meta
          name="twitter:description"
          content={`${tags?.data?.twitter_description}`}
        />
      </Helmet> */}
      <div>
        {isLoading === true ? (
          <Loader />
        ) : error ? (
          <div></div>
        ) : (
          magazine?.data?.posts &&
          Object.keys(magazine?.data?.posts)
            ?.reverse()
            .map((year) => (
              <section key={year} className="sm:mb-24 mb-16 bg-blur">
                <div className="container mx-auto sm:px-7 px-1">
                  <div className="px-3 border-b border-greyb pb-2 mb-5">
                    <h1 className="text-dblack sm:text-2xl text-sm font-bold">
                      MAGAZINE ARCHIVE - {year}
                    </h1>
                  </div>
                  <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 sm:gap-5 gap-3 px-5">
                    {magazine?.data?.posts[year]?.map((magazine, index) => (
                      <div key={index} className="lg:mb-0 sm:mb-5 mb-1">
                        <div className="bg-[#E8E8E8] sm:p-[15px] p-2  sm:mb-[15px] mb-2 rounded-md">
                          <img
                            src={magazine.post_image}
                            alt={magazine.post_title}
                            className="w-full sm:min-h-[16.5rem] sm:max-h-[16.5rem]  lg:min-h-48 lg:max-h-48 xl:min-h-60 xl:max-h-60 min-h-52 max-h-52 object-cover rounded-[10px]"
                          />
                          <button
                            onClick={() =>
                              openMagazineInNewWindow(magazine.magazine_url)
                            }
                            className="text-dblack block mt-5 sm:text-xs text-[10px] font-bold xl:px-10 px-5 text-center py-3 border border-black rounded-md mx-auto"
                          >
                            Read Magazine
                          </button>
                        </div>
                        <div className="text-xs text-dblack leading-5 pl-5">
                          <p className="text-dblack sm:text-xs text-[10px] font-black">
                            {magazine.magazine_issues_no}
                          </p>
                          <p className="font-regular sm:text-xs text-[10px]">
                            {magazine.post_title}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            ))
        )}
      </div>
    </>
  );
};

export default MagazineArchive;
